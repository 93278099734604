export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67')
];

export const server_loads = [0];

export const dictionary = {
		"/(pages)": [45,[6]],
		"/(endpoints)/auth": [67],
		"/(endpoints)/blobs": [58],
		"/(app)/calendars": [~19,[2],[3]],
		"/(app)/events": [~21,[2,5],[3]],
		"/(app)/events/generations": [42,[2,5],[3]],
		"/(app)/events/invites": [~43,[2,5],[3]],
		"/(app)/events/new": [~22,[2,5],[3]],
		"/(app)/events/public": [~44,[2,5],[3]],
		"/(app)/events/[event_id]": [~23,[2,5],[3]],
		"/(app)/events/[event_id]/attendances": [33,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]": [~34,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/accept_host": [~39,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/cannot_go": [~38,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/decline_host": [~37,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/going": [~35,[2,5],[3]],
		"/(app)/events/[event_id]/attendances/[attendance_id]/maybe": [~36,[2,5],[3]],
		"/(app)/events/[event_id]/contact_upload_requests": [31,[2,5],[3]],
		"/(app)/events/[event_id]/duplicates": [41,[2,5],[3]],
		"/(app)/events/[event_id]/edit": [~40,[2,5],[3]],
		"/(app)/events/[event_id]/entries": [24,[2,5],[3]],
		"/(app)/events/[event_id]/entries/[entry_id]": [25,[2,5],[3]],
		"/(app)/events/[event_id]/entries/[entry_id]/broadcasts": [26,[2,5],[3]],
		"/(app)/events/[event_id]/entries/[entry_id]/reactions": [27,[2,5],[3]],
		"/(app)/events/[event_id]/entries/[entry_id]/reactions/[reaction_id]": [28,[2,5],[3]],
		"/(app)/events/[event_id]/invitations": [29,[2,5],[3]],
		"/(app)/events/[event_id]/polls/[poll_id]/votes": [32,[2,5],[3]],
		"/(app)/events/[event_id]/users": [30,[2,5],[3]],
		"/(endpoints)/google/auth": [~59],
		"/(endpoints)/google/sheet_connections": [60],
		"/(endpoints)/google/sheet_connections/[sheet_connection_id]": [61],
		"/(endpoints)/images/generate": [64],
		"/(app)/lists": [~12,[2,4],[3]],
		"/(app)/lists/new": [~13,[2,4],[3]],
		"/(app)/lists/[list_id]": [~14,[2,4],[3]],
		"/(app)/lists/[list_id]/edit": [~18,[2,4],[3]],
		"/(app)/lists/[list_id]/subscriptions": [16,[2,4],[3]],
		"/(app)/lists/[list_id]/subscriptions/[subscription_id]": [17,[2,4],[3]],
		"/(app)/lists/[list_id]/users": [15,[2,4],[3]],
		"/(endpoints)/notification_channels": [65],
		"/(endpoints)/notification_channels/[notification_channel_id]/activate": [~66],
		"/(pages)/pricing": [47,[6]],
		"/(pages)/(prose)/privacy": [50,[6,7]],
		"/(app)/profile": [~11,[2],[3]],
		"/(pages)/search": [~46,[6]],
		"/(endpoints)/spreadsheets": [62],
		"/(endpoints)/spreadsheets/imports": [63],
		"/(endpoints)/stripe/account_link": [~56],
		"/(endpoints)/stripe/accounts": [~54],
		"/(endpoints)/stripe/billing_portal": [~57],
		"/(endpoints)/stripe/checkout_sessions": [53],
		"/(endpoints)/stripe/login_link": [~55],
		"/(pages)/(prose)/terms": [49,[6,7]],
		"/(pages)/(prose)/thank-you": [~48,[6,7]],
		"/(pages)/(prose)/updates": [~51,[6,7,8]],
		"/(pages)/(prose)/updates/[slug]": [~52,[6,7,8]],
		"/(app)/users": [9,[2],[3]],
		"/(app)/users/[user_id]": [~10,[2],[3]],
		"/(app)/[...path]": [20,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';